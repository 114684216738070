.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(250, 250, 250);
  height: 100%;
  width: 100%;
  padding: 30%;
}

.image {
  width: 100%;
  opacity: 0.12;
}
