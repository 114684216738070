.container {
  width: 100%;
  height: 155px;
}

.content {
  display: flex;
  padding: 15px;
  justify-content: space-between;
  /* border-bottom: 5px solid #002445; */
  background-color: #fafafa;
  /* background: linear-gradient(90deg, #0b0e1b, #002445); */
}

.logo {
  height: 120px;
  cursor: pointer;
}

.containerButton {
  display: flex;
  gap: 10px;
}

.containerLinks {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 40px;
}

.header {
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, #002445, #1d68ad);
  background-repeat: no-repeat;
  background-size: 100% 5px;
}
