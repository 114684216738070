.button {
  padding: 12px 20px;
  border: none;
  width: 100%;
  background-color: rgb(211, 230, 255);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  border: 1px solid rgb(110, 110, 110);
}

.buttonIcon {
  padding-left: 10px;
}

.containerForm {
  display: grid;
  grid-template-columns: 3fr 350px;
  /* grid-template-columns: 3fr 1fr; */
  grid-template-rows: 1fr;
  background-color: rgb(243, 253, 255);
  border: 1px solid rgb(110, 110, 110);
}

.images {
  border-left: 0.5px dashed rgb(110, 110, 110);
}
