.item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-size: 0.9rem;
  font-weight: 300;
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.chevron {
  padding: 0px 5px;
  font-size: 0.75rem;
}

.breadcrumbs {
  font-size: 1.1rem;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.item:hover {
  text-decoration: underline;
}

.item:last-of-type:hover {
  text-decoration: none;
}

@media screen and (min-width: 765px) {
  .item {
    font-size: 1.1rem;
  }
}
