.container {
  width: 100%;
  height: 100%;
  max-width: 220px;
  min-height: 340px;
  background-color: #fefefe;
  border-radius: 10px;
  box-shadow: 2px 2px 5px #eeeff0;
  border: 1px solid #eeeff0;
  transition: 0.25s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.productPhoto {
  border: 1px solid #eeeff0;
  border-radius: 2px;
  width: 100%;
  height: 160px;
  position: relative;
  overflow: hidden;
}

.typeLabel {
  position: absolute;
  background-color: rgba(241, 241, 241, 0.8);
  font-size: 0.8rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 600;
  z-index: 1;
  top: 5px;
  right: 5px;
  padding: 5px 10px;
}

.photo {
  width: 100%;
  height: 100%;
  border-radius: 2px;
  object-fit: cover;
  margin: 0px;
  padding: 0px;
  transition: 0.7s ease;
}

.info {
  padding: 8px;
}

.name {
  margin: 15px 0px;
  font-size: 1rem;
  height: 2.7rem;
}
.model,
.brand,
.code {
  font-size: 0.8rem;
  font-weight: 600;
}
.model span,
.brand span,
.code span {
  font-weight: 400;
}

.model {
  padding-bottom: 5px;
}

.code {
  border-top: 1px solid #eeeff0;
  padding: 8px;
  color: rgb(87, 87, 87);
  height: 35px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.container:hover {
  /* background-color: #e2e2e2; */
  background-color: #f8f5f8f8;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.container:hover .photo {
  transform: scale(1.1);
}

/* ------------ TABLET ------------ */
@media screen and (min-width: 765px) and (max-width: 1200px) {
  .container {
    width: 100%;
    height: 100%;
    min-height: 490px;
    /* min-width:220px; */
    max-width: 300px;
  }
  .productPhoto {
    height: 300px;
  }
  .info {
    padding: 15px;
  }
  .name {
    font-size: 1.25rem;
    height: 3rem;
  }
  .model,
  .brand,
  .code {
    font-size: 0.95rem;
  }

  .code {
    padding: 10px 15px;
    height: 40px;
  }
}

/* ------------ DESKTOP ------------ */
@media screen and (min-width: 1201px) {
  .container {
    width: 100%;
    /* min-width:220px; */
    max-width: 300px;
    height: 100%;
    min-height: 490px;
    max-width: none;
  }
  .productPhoto {
    height: 300px;
  }
  .info {
    padding: 15px;
  }
  .name {
    font-size: 1.25rem;
    height: 3rem;
  }
  .model,
  .brand,
  .code {
    font-size: 0.95rem;
  }

  .code {
    height: 40px;
    padding: 10px 15px;
  }
}
