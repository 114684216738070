.mySwiperThumbnail .sliderThumbContainer {
  width: 100%;
  height: 100%;
}

.mySwiperThumbnail .swiper {
  width: 100%;
  height: 100%;
}

.mySwiperThumbnail .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.mySwiperThumbnail .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mySwiperThumbnail .swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.mySwiperThumbnail .swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiperThumbnail2 {
  height: 100%;
  width: 100%;
}

.mySwiperThumbnail {
  height: min-content;
  box-sizing: border-box;
  padding-top: 10px;
}

.mySwiperThumbnail .swiper-slide {
  min-width: 100px;
  width: 150px;
  height: 25%;
  opacity: 0.4;
}

.mySwiperThumbnail .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 0px;
}

/* ------------ TABLET ------------ */
@media screen and (min-width: 765px) and (max-width: 1200px) {
  .sliderThumbContainer {
    width: 100%;
    height: 100%;
    max-width: 650px;
    max-height: 650px;

    display: flex;
    flex-direction: row-reverse;
  }
  .mySwiperThumbnail {
    height: 100%;
    width: min-content;
    box-sizing: border-box;
    padding: 0px;
    margin: 0 10px;
  }
}

/* ------------ DESKTOP ------------ */
@media screen and (min-width: 1201px) {
  .sliderThumbContainer {
    width: 100%;
    max-width: 650px;
    max-height: 650px;
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
  }

  .mySwiperThumbnail {
    padding: 0px;
    margin: 0 10px;
    height: 100%;
    width: min-content;
    box-sizing: border-box;
  }
}
