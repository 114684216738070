.swiper.swiper-fade.swiper-initialized.swiper-horizontal.swiper-pointer-events.swiper-watch-progress.swiper-backface-hidden.mySwiper {
  height: min-content;
}

.swiper-wrapper {
  height: min-content;
}

.mySwiper.swiper {
  width: 100%;
  height: min-content;
  min-height: 350px;
  background-color: rgb(247, 247, 247);
}

.mySwiper {
  width: 100%;
}

.mySwiper .swiper-slide {
  background-position: center;
  background-size: cover;
  height: min-content;
}

.mySwiper .swiper-slide img {
  display: block;
  width: 100%;
  align-content: center;
}

/* ------------ TABLET ------------ */
@media screen and (min-width: 765px) and (max-width: 1200px) {
  .mySwiper.swiper {
    min-height: 350px;
  }
}

@media screen and (min-width: 1201px) {
  .mySwiper.swiper {
    min-height: 350px;
  }
}
