.hamburgerMenu {
  height: 45px;
  cursor: pointer;
  z-index: 10;
}

.bar,
.bar:after,
.bar:before {
  width: 30px;
  height: 3px;
}

.bar {
  transform: translateY(25px);
  background: #000000;
  transition: all 0ms 300ms;
}
.bar.animate {
  background: rgba(255, 255, 255, 0);
}

.bar:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 10px;
  background: #000000;
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.bar:after {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  background: #000000;
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.bar.animate:after {
  top: 0;
  transform: rotate(45deg);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.bar.animate:before {
  bottom: 0;
  transform: rotate(-45deg);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

/****** menu *******/

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: absolute;
  right: -15px;
  top: 56.5px;
  z-index: 10;
}
.container ul {
  display: table-cell;
}

.container ul li {
  list-style: none;
  text-align: right;
  padding: 8px 0px;
}

.container ul li a {
  list-style: none;
  color: #000000;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.1rem;
  /* background: #34495e; */
}
/**/

/************************/

.dropdown {
  padding: 10px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #fff;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  font-size: 18px;
  z-index: 10;
}

.dropdown .dropdownItem1 {
  transform-origin: top center;
  animation: translateX 200ms ease-in-out forwards;
}
.dropdown .dropdownItem2 {
  transform-origin: top center;
  animation: translateX 300ms ease-in-out forwards;
}
.dropdown .dropdownItem3 {
  transform-origin: top center;
  animation: translateX 400ms ease-in-out forwards;
}
.dropdown .dropdownItem4 {
  transform-origin: top center;
  animation: translateX 500ms ease-in-out forwards;
}
.dropdown .dropdownItem5 {
  transform-origin: top center;
  animation: translateX 600ms ease-in-out forwards;
}
.dropdown .dropdownItem6 {
  margin-top: 40px;
  transform-origin: top center;
  animation: translateX 700ms ease-in-out forwards;
}
.dropdown .dropdownItem7 {
  transform-origin: top center;
  animation: translateX 800ms ease-in-out forwards;
}

@keyframes translateX {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }

  80% {
    transform: translateX(-2px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
