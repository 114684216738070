.container {
  width: 100%;
  padding-top: 20px;
  position: relative;
}

.section {
  width: 100%;
}

img.footerIllustration {
  min-width: 100px;
  max-width: 250px;
  width: 80%;
  z-index: 1;
}

.bottom {
  position: relative;
  background-color: chocolate;
  height: auto;
  width: 100%;
}

.footerIllustrationBox {
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  gap: 40px;
  position: absolute;
  bottom: 20px;
}

.footerIllustrationBackground {
  -webkit-clip-path: polygon(0 0%, 100% 65%, 100% 100%, 0% 100%);
  clip-path: polygon(0 40%, 100% 65%, 100% 100%, 0% 100%);
  background: linear-gradient(to bottom left, #97d5e0, #98bac3);
  height: 120px;
  width: 100%;
  position: absolute;
  bottom: 0px;
}
.secondParagraph {
  font-size: 1rem;
  line-height: 1.4rem;
  text-align: center;
  z-index: 1;
}

.content {
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 500px;
}

.mainTitle {
  font-weight: 900;
  font-size: 1.4rem;
  width: max-content;
  padding: 0px 20px 20px 20px;
  background: linear-gradient(120deg, #002445, #1d68ad);
  background-repeat: no-repeat;
  background-size: 100% 4px;
  background-position: 0px 2.3rem;
}
.mainTitle span {
  font-size: 1.5rem;
}

.title {
  padding-bottom: 1rem;
  font-size: 1.2rem;
}

.description {
  font-size: 1rem;
  line-height: 1.4rem;
}
.paragraph {
  padding-bottom: 12px;
}

.boldText {
  font-weight: 600;
}

.lgLogo {
  height: 80px;
}

@media screen and (min-width: 420px) and (max-width: 550px) {
  .container {
    padding: 0px;
    padding-top: 30px;
  }

  .content {
    padding-bottom: 350px;
  }
  .section {
    max-width: 1000px;
  }

  .footerIllustrationBox {
    height: auto;
    width: 100%;
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
}

@media screen and (min-width: 550px) and (max-width: 1200px) {
  .container {
    padding: 0px;
    padding-top: 30px;
  }
  .section {
    max-width: 1000px;
  }
  .content {
    height: 100%;
    width: 100%;
    max-width: 1400px;
    padding: 40px 60px;
    flex-direction: row;
    gap: 30px;
    padding-bottom: 400px;
  }

  .footerIllustrationBox {
    height: auto;
    width: 100%;
    padding-left: 30px;
    display: flex;
    flex-direction: row;
    gap: 50px;
  }
}

@media screen and (min-width: 1201px) {
  .container {
    padding: 0px;
    padding-top: 30px;
  }
  .content {
    height: 100%;
    width: 100%;
    max-width: 1500px;
    padding: 40px 60px;
    flex-direction: row;
    gap: 100px;
    padding-bottom: 550px;

    align-items: center;
    justify-content: center;
  }
  .lgLogo {
    height: 140px;
  }
  .mainTitle {
    font-weight: 900;
    font-size: 2rem;
    width: max-content;
    padding: 0px 30px 20px 30px;
    background: linear-gradient(120deg, #002445, #1d68ad);
    background-repeat: no-repeat;
    background-size: 100% 5px;
    background-position: 0px 2.8rem;
  }
  .mainTitle span {
    font-size: 1.5rem;
  }
  .title {
    padding-bottom: 1.5rem;
    font-size: 1.5rem;
  }
  .secondParagraph {
    font-size: 1.3rem;
    line-height: 1.7rem;
    text-align: center;
  }
  .description {
    font-size: 1.1rem;
    line-height: 1.6rem;
  }

  img.footerIllustration {
    min-width: 100px;
    max-width: 400px;
    width: 80%;
    z-index: 1;
  }

  .footerIllustrationBox {
    height: auto;
    width: 100%;
    padding-left: 50px;
    display: flex;
    flex-direction: row;
    gap: 100px;
  }

  .footerIllustrationBackground {
    -webkit-clip-path: polygon(0 70%, 100% 85%, 100% 100%, 0% 100%);
    clip-path: polygon(0 0%, 100% 65%, 100% 100%, 0% 100%);
    background: linear-gradient(to bottom left, #97d5e0, #98bac3);
    height: 120px;
    width: 100%;
  }
}
