.container {
  background-color: rgb(243, 253, 255);
  border: 1px solid rgb(110, 110, 110);
  max-width: 1300px;
  width: 100%;
}

.containerForm {
  display: grid;
  grid-template-columns: 3fr 350px;
  grid-template-rows: 1fr;
}

.images {
  border-left: 0.5px dashed rgb(110, 110, 110);
}

.titleContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 10px 30px;
  background-color: rgb(226, 250, 255);
  border-bottom: 0.5px solid rgb(110, 110, 110);
}

.title {
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
