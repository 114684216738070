.container {
  height: 100%;
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
}

.title {
  padding-bottom: 30px;
  letter-spacing: 1px;
}

.textarea {
  padding-bottom: 35px;
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 15px;
}
.buttonContainer {
  padding-top: 20px;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.button {
  align-self: flex-end;
}

.error {
  color: red;
}

/**/
.container.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.button {
  padding-top: 30px;
}
