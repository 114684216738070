.container {
  background-color: rgb(246, 250, 255);
  border: 1px solid rgb(110, 110, 110);
  /* max-width: 1300px; */
  width: 100%;
}

.containerForm {
  display: grid;
  grid-template-columns: 3fr 350px;
  grid-template-rows: 1fr;
  background-color: rgb(246, 250, 255);
}

.images {
  border-left: 0.5px dashed rgb(110, 110, 110);
}

.titleContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 10px 30px;
  background-color: rgb(235, 243, 253);
  border-bottom: 0.5px solid rgb(110, 110, 110);
}

.title {
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.fileIcons {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 9px;
  height: auto;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(142, 142, 142);
  border-radius: 5px;
  cursor: pointer;
}

.iconTrash {
  font-size: 1rem;
  color: rgb(0, 0, 0);
}

.fileIcons:hover .iconTrash {
  color: rgb(255, 0, 0);
}
.fileIcons:hover {
  background-color: rgb(255, 231, 231);
  border: 1px solid rgb(255, 0, 0);
}

.fileIcons:active .iconTrash {
  color: rgb(255, 231, 231);
}

.fileIcons:active {
  background-color: rgb(255, 0, 0);
  border: 1px solid rgb(255, 231, 231);
}

@media screen and (min-width: 1250px) and (max-width: 1600px) {
  .container {
    width: 1030px;
  }
}

@media screen and (min-width: 1601px) {
  .container {
    width: 1400px;
  }
}
