.container ul {
  list-style: none;
}

.productType {
  cursor: pointer;
  /* padding: 5px 5px; */
  padding: 5px 0px;
  margin-top: 10px;
  width: 100%;
  text-align: left;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
  background-color: transparent;
  /**/

  font-family: "Roboto","Helvetica","Arial",sans-serif;
font-size: 1.1rem;
font-weight: 500;
}
.brand {
  cursor: pointer;
  /* padding: 4px 10px; */
  padding: 4px 0px;

  width: 100%;
  text-align: left;
  font-size: 0.85rem;
  letter-spacing: 1px;
  border: none;
  background-color: transparent;
  font-weight: 300;

}

.brandsContainer {
  padding: 0px;
}

.active {
  font-weight: 600;
  color: rgb(22, 51, 216);
}

.brand:hover,
.productType:hover {
  background-color: rgb(250, 250, 250);
}
