.container {
  display: flex;
  padding: 15px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 5px solid #002445;
  position: relative;
  background-color: #fafafa;
}

.logo {
  height: 80px;
  cursor: pointer;
}
