.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-width: 300px;
}

.buttonClose {
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.iconClose {
  height: 1.2rem;
  color: #000;
}

.iconFilter {
  padding-left: 5px;
}

.header {
  background-color: rgb(246, 251, 255);
  color: #000;
  padding: 15px 10px 15px 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.headerTitle {
  font-weight: 500;
  font-size: 1.1rem;
}

.buttonClose .iconClose:hover {
  color: rgb(121, 141, 253);
}
