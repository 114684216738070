.button {
  background-color:#f1f1f1;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  transition: 0.3s ease;
  cursor: pointer;
}
.button:hover {
  background-color: #f2f2f2;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.button:hover .whatsappIcon {
  background-color: #179a00;
}

.containerData {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-decoration: none;
}

.whatsappIcon {
  color: #ffffff;
  background-color: #1aab00;
  height: 1.1rem;
  padding: 5px;
  border-radius: 50%;
  transition: 0.3s ease;
}

.contentData {
  display: flex;
  flex-direction: column;
}

.contentData .title {
  color: #000000;
  font-size: 0.65rem;
  font-weight: 400;
}

.contentData .number {
  color: #000000;
  font-weight: 300;
  font-size: 0.85rem;
}
