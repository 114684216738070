.mainTitle {
  /* font-weight: 900; */
  font-size: 1.4rem;
  width: max-content;
  padding: 0px 20px 15px 20px;
  background: linear-gradient(120deg, #002445, #1d68ad);
  background-repeat: no-repeat;
  background-size: 100% 4px;
  background-position: 0px 2.3rem;

  font-weight: 700;
  letter-spacing: 1.2px;
}

@media screen and (min-width: 765px) {
  .mainTitle {
    /* font-weight: 900; */
    font-size: 1.8rem;
    width: max-content;
    /* padding: 0px 30px 20px 30px; */
    padding: 0px 30px 12px 30px;

    background: linear-gradient(120deg, #002445, #1d68ad);
    background-repeat: no-repeat;
    background-size: 100% 5px;
    background-position: 0px 2.6rem;

    /**/

    font-weight: 700;
    letter-spacing: 1.2px;
  }
}
