.container {
  display: flex;
  padding: 30px 10px;
  flex-direction: column;
  align-items: center;
  max-height: 250px;
  gap: 30px;
  color: #ffffff;
  background: linear-gradient(90deg, #0b0e1b, #002445);
}

.containerButton {
  display: flex;
  gap: 10px;
}

.logo {
  height: 90px;
  padding-right: 5px;
}

.links {
  display: none;
}

.firstColumn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.secondColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  text-align: right;
  height: 100%;
}
.dataContainer {
  border-left: 1.5px solid rgba(255, 255, 255, 0.35);
  padding: 5px 15px;
  height: min-content;
}
.dataContact {
  height: min-content;
  line-height: 1.35rem;
  font-weight: 300;
  font-size: 0.9rem;
}

.rightsAndDevleoper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  font-size: 0.8rem;
  font-weight: 300;
}
.secondColumn .dgweb {
  color: #ffffff;
  text-decoration: none;
  font-weight: 400;
  padding-left: 6px;
}

/* ------------ TABLET ------------ */
@media screen and (min-width: 765px) and (max-width: 1200px) {
  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* gap: 30px; */
    justify-content: space-between;
    background: linear-gradient(90deg, #0b0e1b, #002445);
  }

  .containerButton {
    display: flex;
    gap: 10px;
  }

  .links {
    display: contents;
  }
  .dataContact {
    height: min-content;
    line-height: 1.35rem;
    font-weight: 300;
    font-size: 0.8rem;
  }

  .rightsAndDevleoper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 3px;
    font-size: 0.8rem;
    font-weight: 300;
  }
}

@media screen and (min-width: 850px) and (max-width: 1200px) {
  .container {
    padding: 30px 40px;
  }
}

@media screen and (min-width: 1201px) {
  .container {
    display: flex;
    flex-direction: row;
    padding: 20px;
    justify-content: space-between;
    padding: 30px 40px;
    background: linear-gradient(90deg, #0b0e1b, #002445);
  }

  .containerButton {
    display: flex;
    gap: 10px;
  }

  .containerLinks {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 40px;
  }

  .logo {
    height: 110px;
    padding-right: 15px;
  }

  .links {
    display: contents;
  }

  .firstColumn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dataContainer {
    border-left: 2.5px solid rgba(255, 255, 255, 0.15);
    padding: 5px 15px;
    height: min-content;
  }
  .dataContact {
    height: min-content;
    line-height: 1.4rem;
    font-weight: 400;
    font-size: 1rem;
  }

  .secondColumn {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    text-align: right;
  }
  .rightsAndDevleoper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 3px;
    font-size: 0.85rem;
    font-weight: 400;
  }
  .secondColumn .dgweb {
    text-decoration: none;
    font-weight: 500;
    padding-left: 6px;
  }
}
