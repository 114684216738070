.container {
  background-color: #f1f1f1;
  width: 100%;
  border-radius: 15px;
  padding: 15px;
  border-left: 4px solid #dd1b30;
  position: relative;
  box-shadow: 2px 2px 8px #9f9f9f;
}

.container h2 {
  padding: 10px 0px;
  font-size: 1.1rem;
}

.container p {
  font-size: 0.95rem;
  font-weight: 600;
  line-height: 1.5rem;
  font-weight: 400;
}

.icon {
  height: 50px;
}

/* ------------ TABLET ------------ */
@media screen and (min-width: 850px) {
  .container {
    padding: 25px;
  }
  .container h2 {
    font-size: 1.4rem;
  }

  .container p {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}
