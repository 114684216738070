.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  transition: 0.25s ease;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;
  padding: 20px 10px;
  padding-bottom: 80px;

  /* width: max-content; */
  gap: 20px;
  flex-direction: column;
  transition: 0.25s ease;
  justify-content: center;
  align-items: center;
}

.ref {
  position: absolute;
  top: 0px;
  right: 0px;
}

.sectionCards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
}

@media screen and (min-width: 500px) {
  .content {
    padding: 30px 0px;
    gap: 20px;
    min-width: 450px;
    width: max-content;
  }
}

@media screen and (min-width: 765px) {
  .content {
    padding-bottom: 150px;
    min-width: 600px;
    width: max-content;
  }

  .sectionCards {
    gap: 80px;
  }
}

@media screen and (min-width: 1250px) and (max-width: 1600px) {
  .content {
    padding-bottom: 150px;
    min-width: 1030px;
    width: max-content;
  }
}

@media screen and (min-width: 1601px) {
  .content {
    padding-bottom: 150px;
    min-width: 1400px;
    width: max-content;
  }
}
